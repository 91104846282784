import { AppLinks, EchoLogo, Footer, PlantSelector } from '@equinor/echo-framework';
import React from 'react';
import placeholderHomeImage from '../../images/frontpage-brand-placeholder.jpg';
import style from './home.module.css';

export const Home: React.FC = () => {
    return (
        <main className={style.home}>
            <div className={style.plantSelector}>
                <div>
                    <EchoLogo />
                </div>

                <PlantSelector variant="compact" />
            </div>
            <AppLinks />
            <div className={style.brandSliderContainer}>
                <img src={placeholderHomeImage} alt="Application Echo on tablet" />
            </div>
            <Footer />
        </main>
    );
};

