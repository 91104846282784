// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.echo-Home__home-pPNVd {
    display: grid;
    grid-template-rows: 30% 100px auto 56px;
    gap: 1rem;
    height: 100%;
    width: 100%;
}

.echo-Home__plantSelector-AlrNf {
    display: flex;
    justify-content: center;
    align-items: baseline;
    align-self: center;
    flex-flow: wrap;
}

.echo-Home__heading-vnGkQ {
    padding-left: 18px;
}

.echo-Home__brandSliderContainer-qt0N4 {
    display: flex;
    flex: 1 1;
    flex-flow: column-reverse;
    margin: 0;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.echo-Home__appBar-XBn9u {
    margin: 0 auto 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

`, "",{"version":3,"sources":["webpack://./src/components/Home/home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uCAAuC;IACvC,SAAS;IACT,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;IACzB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,uBAAuB;AAC3B","sourcesContent":[".home {\n    display: grid;\n    grid-template-rows: 30% 100px auto 56px;\n    gap: 1rem;\n    height: 100%;\n    width: 100%;\n}\n\n.plantSelector {\n    display: flex;\n    justify-content: center;\n    align-items: baseline;\n    align-self: center;\n    flex-flow: wrap;\n}\n\n.heading {\n    padding-left: 18px;\n}\n\n.brandSliderContainer {\n    display: flex;\n    flex: 1 1;\n    flex-flow: column-reverse;\n    margin: 0;\n    width: 100%;\n    overflow: hidden;\n    object-fit: cover;\n}\n\n.appBar {\n    margin: 0 auto 48px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: flex-start;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `echo-Home__home-pPNVd`,
	"plantSelector": `echo-Home__plantSelector-AlrNf`,
	"heading": `echo-Home__heading-vnGkQ`,
	"brandSliderContainer": `echo-Home__brandSliderContainer-qt0N4`,
	"appBar": `echo-Home__appBar-XBn9u`
};
export default ___CSS_LOADER_EXPORT___;
